<template>
  <BaseCard
    heading="My Events"
    icon="mdi-calendar-multiple"
    :height="$vuetify.breakpoint.smAndUp ? '514' : ''"
  >
    <DataTableExtended
      ref="table"
      :calculate-widths="true"
      :headers="headers"
      :items="eventsList"
      :options.sync="options"
      :footerOptions="footerOptions"
      class="elevation-0 border"
      :loading="loading"
      :no-data-text="loading ? 'Loading…' : 'No data available'"
      :items-per-page.sync="options.itemsPerPage"
      :server-items-length="eventsCount"
      @update:options="fetchTableData(options)"
      :mobile-breakpoint="0"
      :withUrlParams="false"
      data-testid="table-active-events"
    >
      <template v-slot:top>
        <v-row class="pa-3">
          <v-col cols="12" md="12">
            <v-text-field
              outlined
              v-model="search"
              label="Search"
              single-line
              hide-details
              clearable
              data-testid="filter-search-active-events"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.play`]="{ item }">
        <v-tooltip top v-if="$vuetify.breakpoint.smAndUp">
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              x-small
              :color="Pallette.actionButtons.play"
              v-on="on"
              :to="`/store/event/control/${item._key}`"
              data-testid="btn-event-control"
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </template>
          <span>Playlists control</span>
        </v-tooltip>
        <v-btn
          v-else
          block
          dark
          small
          :color="Pallette.actionButtons.play"
          :to="`/store/event/control/${item._key}`"
          data-testid="btn-event-control"
        >
          <v-icon>mdi-play</v-icon>
          Playlists control
        </v-btn>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
    </DataTableExtended>
  </BaseCard>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import WsConnMixin from "@/mixins/websocket-connection.js"
import EventCommonMixin from "@/mixins/event/common.js"
import DataTableExtended from "@/components/table/DataTableExtended"

export default {
  name: "ActiveEventsWidget",

  components: { DataTableExtended },

  mixins: [GeneralMixin, WsConnMixin, EventCommonMixin],

  data: () => ({
    headers: [
      {
        text: "",
        value: "play",
        sortable: false,
        width: 40,
      },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: true,
      },
    ],
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    footerOptions: {
      "items-per-page-text": "",
      "items-per-page-options": [5],
    },
    serverPagination: {
      p: 1,
      pp: 5,
      sort: "",
      order: "",
    },
    search: "",
  }),

  watch: {
    search() {
      this.fetchTableData(this.$refs?.table?.options)
    },
  },

  created: function () {
    let self = this
    self.$on("event-list", () => {
      self.fetchTableData(this.$refs?.table?.options)
    })
    self.initWebsocketConnection()
  },

  methods: {
    fetchTableData: function (options) {
      let self = this

      if (options) {
        self.serverPagination.sort =
          options.sortBy && options.sortBy[0] ? options.sortBy[0] : "name"
        self.serverPagination.order =
          options.sortDesc && options.sortDesc[0] ? "DESC" : "ASC"
      }

      let params = {
        p: self.options.page,
        pp: self.options.itemsPerPage,
        // keep: '' // @todo define what fields acualy need for events interface
      }
      if (self.search !== "") params.search = self.search
      if (self.serverPagination.sort !== "")
        params.sort = self.serverPagination.sort
      if (self.serverPagination.order !== "")
        params.order = self.serverPagination.order
      params.user_key = self.currentUser._key
      self.loading = true
      self.$store.dispatch("getEventsList", { params }).then(() => {
        self.loading = false
      })
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
      eventsList: "eventsList",
      eventsCount: "eventsCount",
      currentUser: "currentUser",
    }),
  },
}
</script>
