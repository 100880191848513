import _ from "lodash"
import { mapGetters } from "vuex"
import WsConnMixin from "@/mixins/websocket-connection.js"

export default {
  mixins: [WsConnMixin],

  created: function () {
    let self = this
    self.$root.$on("eStartStopCurrentEvent", this.switchStarted)
  },

  beforeDestroy() {
    this.$root.$off("eStartStopCurrentEvent", this.switchStarted)
  },

  methods: {
    initWebsocketConnection(subscribes) {
      let self = this
      if (self.websocket_common_conn) {
        let subscribesList = {
          ...{ list: true, playlist: false },
          ...subscribes,
        }
        self.websocket_common_conn.onmessage = function (value) {
          if (Object.keys(value).length > 0) {
            let data = JSON.parse(value.data)
            let path = _.get(data, "path", "")
            if (path === "1.0.auth.login") {
              if (_.get(data, "status", "") === "success") {
                if (subscribesList.list) {
                  self.websocket_common_conn.send(
                    JSON.stringify({
                      path: "1.0.events.list",
                      args: {},
                    })
                  )
                }
                if (subscribesList.playlist) {
                  self.websocket_common_conn.send(
                    JSON.stringify({
                      path: "1.0.events.subscribe",
                      args: { event_key: self.activeEvent._key },
                    })
                  )
                }
              }
            } else if (path === "1.0.events.list") {
              self.$emit("event-list")
            } else if (path === "1.0.events.delete") {
              self.$emit("event-deleted")
            } else if (path === "1.0.events.create") {
              self.$emit("event-created", data)
            } else if (path === "1.0.events.update") {
              self.$emit("event-edited", data)
            } else if (
              path === "1.0.events.start" ||
              path === "1.0.events.stop"
            ) {
              if (_.get(data, "status", "") === "success") {
                let isStarted = data?.data?.data?.is_started
                self.$store.dispatch("saveEventItem", { is_started: isStarted })
              }
              self.$store.dispatch("loadingStartStopEvent", false)
            } else if (path === "1.0.ev.refresh.page") {
              self.$root.$emit("event-page-refresh")
            } else if (path === "1.0.events.subscribe") {
              self.$emit("events-subscribe-playlist", data?.data?.data)
            }
          }
        }
        self.websocket_common_conn.onopen = function () {
          // console.log('onopen')
        }
        self.websocket_common_conn.onclose = function () {
          // console.log('conn_websocket_company error --', e)
        }
      }
    },
    switchStarted(action = false) {
      let self = this
      if (self.activeEvent._key) {
        if (action) {
          self.websocket_common_conn.send(
            JSON.stringify({
              path: "1.0.events.start",
              args: { event_key: self.activeEvent._key },
            })
          )
        } else {
          self.websocket_common_conn.send(
            JSON.stringify({
              path: "1.0.events.stop",
              args: { event_key: self.activeEvent._key },
            })
          )
        }
      } else {
        self.$store
          .dispatch("saveEventItem", {
            is_started: !self.activeEvent.is_started,
          })
          .then(() => {
            self.$store.dispatch("loadingStartStopEvent", false)
          })
      }
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeEvent: "activeEvent",
      ordersRegisteredList: "ordersRegisteredList",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      Pallette: "get_Pallette",
      loadingStartStopEvent: "loadingStartStopEvent",
    }),
  },
}
